import profilePic from "./profile-pic.jpg";
import insta from "./instagram.png";
import fb from "./facebook.png";
import twitter from "./twitter.png";
import linkedIn from "./linkedin.png";
export default {
  profilePic: profilePic,
  fb: fb,
  insta: insta,
  twitter: twitter,
  linkedIn: linkedIn,
};
