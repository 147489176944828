import Profile from "./Profile.json";
export default Profile;

//   work_experience 👷🏻‍♀️
//   awards 🏆
//   projects 🅿
//   publications 📰
//   skill 👩🏻‍💻
//   language 🔠
//   education 🎓
